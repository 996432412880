#services
    display: flex
    flex-direction: column
    text-align: center
    margin-top: 100px
    // padding-left: var(--app-spacing)
    // padding-right: var(--app-spacing)
    width: 95%

    // @media screen and (max-width: 550px)
    //     width: 100%

    > .rows
        display: flex
        flex-direction: row
        gap: 32px

        > .content
            flex: 1
            font-size: 1rem
            line-height: 150%
            text-align: justify

            display: grid
            grid-template-columns: repeat(auto-fit, minmax(300px, 1fr))
            gap: 0px

            > section
                border-style: solid
                border-width: 0px 1px 1px 0px
                border-color: #F0F0F0
                padding: 32px

                > img
                    fill: #c3cdbf
                    width: 48px
                    height: 48px
                    position: relative
                    display: block
                    box-sizing: border-box
                    margin: auto

                > h3
                    margin: 8px 0px
                    text-align: center

                > p
                    margin: 0

                > .divider
                    display: flex
                    text-align: center
                    padding-top: 2px
                    padding-bottom: 4px
                    box-sizing: border-box

                    > span
                        border-top: 2px solid #F0F0F0
                        width: 71px
                        height: 2px
                        margin: 0 auto

            @media screen and (min-width: 2300px)
                > section
                    border-width: 1px 0px 1px 1px
                > section:nth-of-type(3)
                    border-width: 1px
                > section:nth-of-type(4)
                    border-width: 0px 0px 1px 1px
                > section:nth-of-type(5)
                    border-width: 0px 1px 1px 1px

            @media screen and (min-width: 1548px)
                > section
                    border-width: 1px 0px 0px 1px
                > section:nth-of-type(2)
                    border-width: 1px 1px 0px 1px
                > section:nth-of-type(4), > section:nth-of-type(5)
                    border-width: 1px

            @media screen and (max-width: 1547px)
                > section
                    border-width: 1px 1px 0px 1px
                > section:nth-last-of-type(1)
                    border-width: 1px

        > .cover
            flex: 1
            background: url('../../assets/projects/services.jpg')
            // background: url('../../assets/projects/services.png')
            background-repeat: no-repeat
            background-position: center
            background-size: cover
            // background-size: contain

        // > .wrapper
        //     display: flex
        //     flex-direction: row
        //     justify-content: space-around
        //     gap: var(--app-spacing)
        //     margin-top: 32px

        //     > div
        //         background-repeat: no-repeat
        //         background-position: center
        //         background-size: cover
        //         width: 500px
        //         height: 500px

        //     > .interior-design
        //         background: var(--color-placeholder)

        //     > .home-decor
        //         background: var(--color-placeholder)

    // @media screen and (max-width: 1304px)
    @media screen and (max-width: 1000px)
        > .rows
            flex-direction: column

            > .content
                gap: 6px

                > section
                    border-width: 1px

            > .cover
                flex: none
                height: 645px
                width: 100%

        // > .wrapper
        //     flex-direction: column

        //     > div
        //         width: 100%
        //         height: 350px
