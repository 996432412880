html
  height: 100%
  width: 100%

body
  --font: 'Lato'
  --font-header: 'Lato'
  // --font-header: 'Linux-Libertine'

  --primary-color: rgb(194, 97, 58)
  --primary-color-alpha: rgb(194 97 58 / 71%)
  --primary-color-varient: rgb(212, 212, 212)
  --primary-color-varient-alpha: rgb(212 212 212 / 71%)
  --primary-color-varient-dark: rgb(165, 165, 165)
  --primary-color-varient-dark-alpha: rgb(165 165 165 / 71%)
  --secondary-color: rgb(43, 21, 13)
  --secondary-color-alpha: rgb(43 21 13 / 71%)
  --secondary-color-hover: rgb(207, 104, 63)
  --tertiary-color: #C7A472
  --tertiary-color-hover: #e1bc88
  --quaternary-color: #c3cdbf
  --quaternary-color-hover: #e0e8dd
  --theme-color: #C7A472

  --color-text: #555454
  --color-header-text: var(--primary-color-varient)
  --color-header-background: transparent
  --color-header-background-sticky: rgba(255, 255, 255, 100)
  --color-footer-text: var(--secondary-color-alpha)
  --color-footer-background: transparent
  --color-background: #fff
  --color-placeholder: #efebeb
  --app-spacing: 27.5px

  --transition-color: 250ms cubic-bezier(0.4, 0, 0.2, 1)
  --transition-opacity: 250ms cubic-bezier(0.4, 0, 0.2, 1)

  --menu-button-bg-color: var(--primary-color-varient-alpha)
  --menu-button-bg-shadow: 0 0 20px var(--primary-color-varient-dark-alpha)

  --button-bg-color-disabled: rgba(204, 204, 204, 0.27)
  --button-bg-color-grey: rgb(66, 66, 66)
  --button-bg-color-blue: rgb(89, 108, 187)
  --button-bg-color-blue-flat: rgba(21, 61, 111, 0.44)
  --button-bg-color-dark: rgb(40 44 51)
  --button-bg-color-green: rgb(27 183 107)
  --button-bg-color-pink: rgb(237 109 216)
  --button-bg-color-dark-pink: rgb(237 109 216)
  --button-bg-color-yellow: rgb(244 240 212)

  --button-bg-color-hover-grey: rgb(100, 100, 100)
  --button-bg-color-hover-blue: rgb(98, 126, 234)
  --button-bg-color-hover-blue-flat: rgba(21, 61, 111, 0.44)
  --button-bg-color-hover-dark: rgb(40 44 51)
  --button-bg-color-hover-green: rgb(15 157 88)
  --button-bg-color-hover-pink: rgb(247 126 227)
  --button-bg-color-hover-dark-pink: rgb(247 126 227)
  --button-bg-color-hover-yellow: rgb(255 242 212)

  --button-text-color-disabled: rgba(0, 0, 0, 0.26)
  --button-text-color-grey: white
  --button-text-color-blue: white
  --button-text-color-blue-flat: rgb(109, 168, 255)
  --button-text-color-dark: rgb(204 204 204)
  --button-text-color-green: white
  --button-text-color-pink: white
  --button-text-color-dark-pink: white
  --button-text-color-yellow: var(--primary-color)

  --button-border-color-grey: ''
  --button-border-color-blue: ''
  --button-border-color-blue-flat: 1px solid rgba(21, 61, 111, 0.44)
  --button-border-color-dark: solid 1px rgb(60 62 66)
  --button-border-color-green: ''
  --button-border-color-pink: ''
  --button-border-color-dark-pink: solid 1px rgb(220 125 138)
  --button-border-color-yellow: ''

  --button-border-color-hover-grey: ''
  --button-border-color-hover-blue: ''
  --button-border-color-hover-blue-flat: rgb(33, 114, 229)
  --button-border-color-hover-dark: rgb(156 173 181) !important
  --button-border-color-hover-green: ''
  --button-border-color-hover-pink: ''
  --button-border-color-hover-dark-pink: rgb(247 126 227) !important
  --button-border-color-hover-yellow: ''

  --button-svg-color-grey: white
  --button-svg-color-blue: white
  --button-svg-color-blue-flat: white
  --button-svg-color-dark: white
  --button-svg-color-green: white
  --button-svg-color-pink: white
  --button-svg-color-dark-pink: white
  --button-svg-color-yellow: var(--primary-color)

  --button-shadow-color-disabled: inset 0px 0px 5px rgb(208 208 208)

  --wallet-balance-text-color: var(--primary-color)
  --wallet-balance-bg-color: rgb(211 207 199)
  --wallet-balance-bg-color-hover: rgb(231 226 219)

  --wallet-main-network-bg-color: rgb(41 182 175 / 20%)
  --wallet-main-network-text-color: rgb(41, 182, 175)

  --wallet-test-network-bg-color: var(--secondary-color-alpha)
  --wallet-test-network-text-color: var(--primary-color)

  --wallet-block-number-text-color: rgb(247 201 208)

  --wallet-nft-bg-color-blue: linear-gradient(0deg, rgb(187 187 255) -25%, rgb(89 108 187) 15%, rgb(169 179 218) 100%)
  --wallet-nft-bg-color-green-flat: rgb(68 152 98 / 8%)
  --wallet-nft-bg-color-white: rgb(231 207 211 / 25%)
  --wallet-nft-bg-color-yellow: var(--secondary-color)

  --wallet-nft-bg-color-blue-hover: ''
  --wallet-nft-bg-color-green-flat-hover: ''
  --wallet-nft-bg-color-white-hover: rgb(241 226 229 / 30%)
  --wallet-nft-bg-color-yellow-hover: var(--secondary-hover-color)

  --wallet-nft-color-blue: rgb(235, 239, 255)
  --wallet-nft-color-green-flat: rgb(168 183 180 / 75%)
  --wallet-nft-color-white: white
  --wallet-nft-color-yellow: var(--primary-color)

  --wallet-nft-border-color-blue: ''
  --wallet-nft-border-color-green-flat: solid 1px rgb(105 117 115 / 59%)
  --wallet-nft-border-color-white: ''
  --wallet-nft-border-color-yellow: ''

  margin: 0
  padding: 0
  font-family: var(--font)
  background-color: var(--color-background)
  color: var(--secondary-color)

  -webkit-font-smoothing: antialiased
  -moz-osx-font-smoothing: grayscale

h1,
h2,
h3,
h4
  font-family: var(--font-header)
  color: var(--primary-color)
  font-weight: 300

h1
  font-size: 2.6rem
  margin: 24px 0

p
  margin: 12px 0

a
  color: var(--tertiary-color)
  transition: color var(--transition-color) !important
  text-decoration: none !important

a:hover
  color: var(--tertiary-color-hover)

  /* width */
::-webkit-scrollbar
  width: 8px

/* Track */
::-webkit-scrollbar-track
  background: var(--primary-color-varient-alpha)
  box-shadow: inset 0 0 5px var(--primary-color-varient)

/* Handle */
::-webkit-scrollbar-thumb
  background: var(--secondary-color)
  box-shadow: inset 0 0 5px var(--primary-color-varient-alpha)
  border-radius: 10px

/* Handle on hover */
::-webkit-scrollbar-thumb:hover
  background: var(--secondary-color-hover)

@font-face
  font-family: 'Linux-Libertine'
  src: local('Linux-Libertine') url('./assets/fonts/Linux-Libertine.ttf.woff') format('woff')
  src: url('./assets/fonts/Linux-Libertine.ttf.svg#Linux-Libertine') format('svg')
  src: url('./assets/fonts/Linux-Libertine.ttf.eot')
  src: url('./assets/fonts/Linux-Libertine.ttf.eot?#iefix') format('embedded-opentype')
  font-weight: normal
  font-style: normal
