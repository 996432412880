#the-studio
    display: flex
    flex-direction: column
    text-align: center
    margin-top: 100px
    // padding-left: var(--app-spacing)
    // padding-right: var(--app-spacing)
    width: 95%

    @media screen and (max-width: 700px)
        margin-top: 50px

    > .columns
        display: flex
        flex-direction: row
        gap: 32px

        > .content
            width: 50%
            flex: 1
            font-size: 1.2rem
            line-height: 150%
            text-align: justify

        > .cover
            background: url('../../assets/projects/studio5.jpg')
            background-repeat: no-repeat
            background-position: center
            background-size: cover
            width: 50%

            @media screen and (max-width: 700px)
                display: none
