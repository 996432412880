#contact
    position: relative
    text-align: center
    margin-top: 100px
    width: 100%

    .contact-details
        display: flex
        flex-direction: row
        gap: 4px
        justify-content: center
        margin: 16px 0

    .content
        display: flex
        flex-direction: column
        align-items: center
        gap: var(--app-spacing)

        > div
            width: 50%

            @media screen and (max-width: 700px)
                width: calc(100% - 2 * var(--app-spacing))

        .form-service
            text-align: left

    // @media screen and (max-width: 550px)
    //     > .content
    //         flex-direction: column

    //         .divider
    //             display: none

    .banner
        background-image: url('../../assets/projects/footer11.jpg')
        background-repeat: no-repeat
        background-position: top
        background-size: cover
        box-shadow: 0 1px 11px var(--primary-color-alpha)
        width: 100%
        height: 100px
        margin-top: 50px

        @media screen and (max-width: 700px)
            background-image: url('../../assets/projects/footer-mobile3.png')
            height: 500px
