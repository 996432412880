.modal
    display: flex
    align-items: center
    justify-content: center
    z-index: 20

    &-wrapper
        position: relative
        height: 80vh
        width: 50vw
        max-height: 800px
        outline: none
        box-shadow: 0 0 10px 5px var(--secondary-color-alpha)
        border-radius: 8px
        background-color: white
        // background-color: #f5d3bf
        z-index: 20

        @media screen and (max-width: 700px)
            height: 90vh
            width: 95vw

        > .close
            position: absolute
            right: 3px
            top: 3px
            cursor: pointer
            transition: color var(--transition-color), background-color var(--transition-color), box-shadow var(--transition-color)
            border-radius: 16px
            padding: 2px
            color: white

            &:hover
                // background-color: #9c9c9c94
                // box-shadow: 0px 0px 10px rgb(183 183 183)
                color: var(--primary-color-varient)

        > .columns
            height: 100%
            display: flex
            flex-direction: column
            gap: 4px

            > .content
                flex: 1
                font-size: 1em
                line-height: 150%
                text-align: justify
                padding: var(--app-spacing)

                .MuiGrid-container
                    > div:first-of-type
                        flex-basis: 25% !important
                        flex-grow: 0 !important
                        max-width: 25% !important

                    > div:nth-of-type(2)
                        flex-basis: 60% !important
                        flex-grow: 0 !important
                        max-width: 60% !important

                    img
                        height: 76px
                        width: 76px

                .MuiTypography-alignRight
                    display: none

                .MuiTypography-gutterBottom
                    display: none

                > h2
                    margin-top: 0
                    text-align: center

                > .fineprint
                    font-size: 12px
                    line-height: 14px
                    margin-top: 16px

                ul
                    margin-top: 0

                > .contact-form
                    display: flex
                    flex-direction: row
                    gap: 32px
                    margin-top: 32px

                    > .form-email
                        flex: 1

            > .cover
                background-image: url('../../assets/projects/holiday-special3.jpg')
                background-repeat: no-repeat
                background-position: center
                background-size: cover
                height: 100%
                border-radius: 8px 8px 0 0
