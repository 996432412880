$num-of-testimonials: 4

#testimonials
    width: 95%
    min-height: 200px
    display: flex
    flex-direction: column
    text-align: center
    margin-top: 100px

    @media screen and (max-width: 700px)
        min-height: 266px

    > .content
        width: 100%
        min-height: 134px
        position: relative
        overflow-x: hidden
        font-size: 1.2rem
        line-height: 150%
        text-align: justify
        font-style: italic
        overflow-y: hidden

        @media screen and (max-width: 700px)
            font-size: 1rem

        > div
            position: absolute
            width: 50%
            height: fit-content
            // max-width: 650px
            text-align: center
            transform: translateX(150vw)
            left: 50%

            @media screen and (max-width: 700px)
                width: 95%

    @for $i from 1 through $num-of-testimonials
        .selected-#{$i}
            @if ($i - 1) == 0
                > div:nth-of-type($num-of-testimonials)
                    transform: translateX(-200vw) !important
                    transition: transform .5s ease-out
            @else
                > div:nth-of-type(#{($i - 1)})
                    transform: translateX(-200vw)
                    transition: transform .5s ease-out

            > div:nth-of-type(#{$i})
                position: relative
                transform: translateX(-50%)
                transition: transform .4s ease-in-out
