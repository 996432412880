$projects: 11

#projects-mobile
    position: relative
    text-align: center
    margin-top: 100px
    margin-bottom: 35px
    width: 96%
    height: 100%
    overflow-x: hidden
    overflow-y: visible

    // @media screen and (max-width: 534px)
    //     width: 95%

    .fade-right
        position: absolute
        right: 0
        height: 534px
        width: 1%
        background: linear-gradient(90deg, rgba(255,255,255,0) 0%, rgba(255,255,255,1) 100%)
        z-index: 10

    .fade-left
        position: absolute
        left: 0
        height: 534px
        width: 1%
        background: linear-gradient(90deg, rgba(255,255,255,1) 0%, rgba(255,255,255,0) 100%)
        z-index: 10

    .highlights
        display: flex
        flex-direction: row
        // justify-content: center
        gap: var(--app-spacing)
        transition: transform .2s ease
        @for $i from 1 through $projects
            > div:nth-of-type(#{$i})
                background: url('../../assets/highlights/' + $i + '.jpg')
                background-repeat: no-repeat
                background-position: center
                background-size: contain
                width: 534px
                height: 534px
                flex-shrink: 0
                cursor: pointer
                transition: filter .2s ease

                &:hover
                    filter: brightness(1.1)

                    @media only screen and (min-device-width: 768px) and (max-device-width: 1024px)
                        filter: none
                    @media only screen and (max-device-width: 480px)
                        filter: none

            &.selected-#{$i}
                transform: translateX(calc(50% - 534px / 2 - (534px + var(--app-spacing)) * ($i - 1)))

                > div:nth-of-type(#{$i})
                    filter: brightness(1.1)
                    cursor: default

                    @media only screen and (min-device-width: 768px) and (max-device-width: 1024px)
                        filter: none
                    @media only screen and (max-device-width: 480px)
                        filter: none

        .loopback
            width: 100px
            transition: fill var(--transition-color)

            &:hover svg
                fill: var(--tertiary-color-hover)

    // @media screen and (max-width: 534px)
    //     .highlights
    //         gap: 0
