#projects
    position: relative
    text-align: center
    margin-top: 100px
    margin-bottom: 35px
    width: 95%
    height: 100%
    overflow-x: hidden
    overflow-y: visible

    .ReactGridGallery > div
        justify-content: center
