.home
  display: flex
  flex-direction: column
  margin: auto
  width: 100%//calc(100% - 55px)
  height: 100%
  align-items: center

  > .cover
    position: relative
    background: url('../../assets/projects/header4.webp')
    background-repeat: no-repeat
    background-position: center
    background-size: cover
    box-shadow: 0 -1px 11px var(--primary-color-alpha)
    width: 100%
    height: 80vh

    > .contact
      border: 2px solid white
      position: absolute
      bottom: 72px
      left: 50%
      transform: translateX(-50%)
      padding: 8px
      color: white
      font-size: 21px
      width: 255px
      text-align: center

      &.hidden
        display: none

    @media screen and (max-width: 700px)
      background-image: url('../../assets/projects/header-mobile2.jpg')
