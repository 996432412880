#about
    display: flex
    flex-direction: column
    text-align: center
    margin-top: 100px
    // padding-left: var(--app-spacing)
    // padding-right: var(--app-spacing)
    width: 95%

    // @media screen and (max-width: 1350px)
    //     width: 80%

    > .wrapper
        display: flex
        flex-direction: row
        justify-content: space-between
        gap: var(--app-spacing)

        > .content
            flex: 1
            font-size: 1.2rem
            line-height: 150%
            text-align: justify

            > .studio > h3
                margin-top: 0

        > .profile
            flex: 1
            background: url('../../assets/profile2.png')
            background-repeat: no-repeat
            background-position: center
            background-size: cover
            transform: rotateY(180deg)
            width: 489px
            min-height: 400px
            // height: 487px

        @media screen and (max-width: 1350px)
            display: block

            > .profile
                width: 100%
                height: 350px
                margin-bottom: var(--app-spacing)
