#app
  position: relative
  height: 100%
  display: flex
  flex-direction: column

  > header,

  > footer
    display: flex
    flex-direction: row
    gap: 16px
    padding: var(--app-spacing)

  > header
    position: absolute
    width: calc(100% - var(--app-spacing) * 2)
    background-color: var(--color-header-background)
    align-items: center
    justify-content: space-between
    min-height: 70px
    padding-top: 8px
    padding-bottom: 8px
    font-size: 18px
    z-index: 10

    a:hover
      color: white !important

    &.sticky
      position: fixed
      top: 0
      z-index: 1000
      background-color: var(--color-header-background-sticky)
      box-shadow: 0 3px 5px rgba(57, 63, 72, 0.3)
      transition: background-color var(--transition-color), box-shadow var(--transition-color)

      a:hover
        color: var(--secondary-color-hover) !important

      > .contact-phone
        color: black
        opacity: 1

        &:hover
          color: var(--secondary-color-hover)

    > .contact-phone
      font-size: 23px
      width: 159px
      text-align: center
      // font-weight: bold
      color: var(--secondary-color-varient)
      transition: opacity var(--transition-opacity), color var(--transition-color) !important
      opacity: 1

      &.hidden
        opacity: 0

      &:hover
        color: var(--secondary-color-hover)

      @media screen and (max-width: 700px)
        font-size: 14px
        width: 91px
        color: white

    .logo
      height: 25px

      @media screen and (max-width: 700px)
        height: 20px

    @media screen and (max-width: 700px)
      gap: 0

  > header > section:first-of-type
    display: flex
    flex-direction: row
    align-items: center
    justify-content: center

  > header > section:first-of-type > a, & > header > section:first-of-type > div
    margin-left: 96px

  > footer
    position: absolute
    bottom: 0
    left: 0
    display: flex
    flex-direction: row
    justify-content: space-between
    background-color: var(--color-footer-background)
    // margin-top: 100px

    @media screen and (max-width: 700px)
      padding: 6px
      left: 0
      bottom: 0
      // left: 50%
      // transform: translateX(-50%)
      // text-align: center
      > div
        width: 222px

  > footer *
    color: var(--color-footer-text)

  > .content
    flex: 1
